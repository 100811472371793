import store from '@/store'
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {firestore, functions} from '@/plugins/firebase.init';
import {httpsCallable} from 'firebase/functions'
import {applicationStore} from '@/store/modules/application';
import {
  addDoc,
  arrayRemove,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  writeBatch
} from 'firebase/firestore';
import axios from '@/plugins/axios';
import {appConfig, dynamicLinkConfig} from '@/plugins/firebase.config';
import {profileStore} from '@/store/modules/profile';
import rfdc from 'rfdc';
import {ChatStoreState} from '@/domain/model/types';
import {archiveStore} from '@/store/modules/archive/ArchiveStore';
import {
  business,
  businessCaseNote,
  businessCaseNoteReplies,
  businessCaseNoteReply,
  businessCaseNotes,
  businessCustomerNote,
  businessCustomerNoteReplies,
  businessCustomerNoteReply,
  businessCustomerNotes,
  chat,
  chatMessage
} from '@/data/firebase';
import deleteProperty = Reflect.deleteProperty;

const clone = rfdc({proto: true})

const defaultState: ChatStoreState = {
  saving: false,
  error: '',
  noteReplies: [],
  caseNotes: [],
  customerNotes: [],
  selectedNote: null,
  repliesLoaded: false,
  notesLoaded: false,
  forwardedMessage: null,
  forwardedMessages: null,
  chatFullPath: ''
}
@Module({name: 'chat-store', dynamic: true, store})
export default class ChatStore extends VuexModule {

  // todo: revise this!!!
  private _saving: boolean = clone(defaultState).saving
  private _error: string = clone(defaultState).error
  private _noteReplies: any[] = clone(defaultState).noteReplies
  private _caseNotes: any[] = clone(defaultState).caseNotes
  private _customerNotes: any[] = clone(defaultState).customerNotes
  private _selectedNote: any = clone(defaultState).selectedNote
  private _repliesLoaded: boolean = clone(defaultState).repliesLoaded
  private _notesLoaded: boolean = clone(defaultState).notesLoaded
  private _forwardedMessage: any = clone(defaultState).forwardedMessage
  private _forwardedMessages: any[] | null = clone(defaultState).forwardedMessages
  private _chatFullPath: string = clone(defaultState).chatFullPath

  get saving() {
    return this._saving
  }

  get error() {
    return this._error
  }

  get isChatMember() {
    const chat = this.context.rootGetters.selectedTextSession
    return chat && chat.memberIDs && chat.memberIDs.includes(this.userId)
  }

  get user() {
    return profileStore.t2bUser
  }

  get userId() {
    return this.user?.id
  }

  get noteReplies() {
    return this._noteReplies
  }

  get repliesCount() {
    return this._noteReplies.length
  }

  get selectedNote() {
    return this._selectedNote
  }

  get repliesLoaded() {
    return this._repliesLoaded
  }

  get notesLoaded() {
    return this._notesLoaded
  }

  get caseNotes() {
    return this._caseNotes
  }

  get customerNotes() {
    return this._customerNotes
  }

  get messageToForward() {
    return this._forwardedMessage || this._forwardedMessages
  }

  get lastVisitedChat() {
    return this._chatFullPath
  }

  @Mutation
  private resetChatStoreState() {
    Object.keys(defaultState).forEach((key) => {
      this['_' + key] = clone(defaultState)[key]
    })
  }

  @Mutation
  setRepliesLoaded(value) {
    this._repliesLoaded = value
  }

  @Mutation
  setNotesLoaded(value) {
    this._notesLoaded = value
  }

  @Mutation
  selectNote(note) {
    this._selectedNote = note
  }

  @Mutation
  resetNoteReplies() {
    this._noteReplies = []
  }

  @Mutation
  resetCaseNotes() {
    this._caseNotes = []
  }

  @Mutation
  resetCustomerNotes() {
    this._customerNotes = []
  }

  @Mutation
  putNoteReply(reply) {
    this._noteReplies.push(reply)
  }

  @Mutation
  updateNoteReply({reply, oldIndex}) {
    this._noteReplies.splice(oldIndex, 1, reply)
  }

  @Mutation
  removeNoteReply(oldIndex) {
    this._noteReplies.splice(oldIndex, 1)
  }

  @Mutation
  setSaving(value) {
    this._saving = value
    if (value) {
      this._error = ''
    }
  }

  @Mutation
  setError(value) {
    this._error = value
  }

  @Action
  public async changeChatTitle({chatId, title}) {
    this.setSaving(true)
    try {
      await updateDoc(chat(chatId), 'title', title)
    } catch (err: any) {
      this.setError(err.message)
      console.error(err)
    }
    this.setSaving(false)
  }

  @Action
  public async muteChat({chatId, duration}) {
    const uid = profileStore.t2bUser?.id
    if (!uid) {
      return
    }
    try {
      this.setSaving(true)
      let updateValue: any
      if (duration > 0) {
        const now = Timestamp.now();
        updateValue = new Timestamp(now.seconds + duration, now.nanoseconds)
      }
      if (duration === 0) {
        updateValue = deleteField()
      }
      await updateDoc(chat(chatId), `mutedBy.${uid}`, updateValue)
      this.setSaving(false)
      return true
    } catch (err: any) {
      this.setError(err.message)
      console.error(err)
    }
    this.setSaving(false)
    return false
  }

  @Action
  public async deleteChatMember({chatId, member}) {
    const memberId: string = member.associate?.id || member.uid || member.id
    try {
      await updateDoc(chat(chatId),
        `members.${memberId}`, deleteField(),
        'memberIDs', arrayRemove(memberId))
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public updateMessageSeenBy(message: any) {
    const isNotSender = message.sender.uid !== this.userId;
    const notSeen = !message.seenBy || !message.seenBy[this.userId!];
    if (this.isChatMember && isNotSender && notSeen) {
      console.log(`message: ${message.id}, seen by was updated`)
      const data = {seenBy: {[this.userId!]: Timestamp.now()}};
      return setDoc(chatMessage(message.textSessionId, message.id), data, {merge: true})
    }
    return null
  }


  @Action
  public async updatePresence(presence: { presentIn?: string, notPresentIn?: string }) {
    console.log(`presentIn=${presence.presentIn}, notPresentIn=${presence.notPresentIn}`)
    if (!this.userId) {
      console.log('user is not present')
      return
    }
    if (!presence.presentIn && !presence.notPresentIn) {
      console.log('no chat IDs to update')
      return
    }
    if (!this.context.rootGetters?.selectedTextSession?.memberIDs?.includes(this.userId)) {
      console.log('user is not the chat member (admin)')
      return
    }
    try {
      const batch = writeBatch(firestore);
      if (presence.presentIn) {
        batch.update(chat(presence.presentIn), `presence.${this.userId}`, false)
      }
      if (presence.notPresentIn) {
        batch.update(chat(presence.notPresentIn), `presence.${this.userId}`, deleteField())
      }
      await batch.commit()
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async updateTyping({chatId, typing}) {
    if (!this.userId || !chatId) {
      console.error('user or chat is not present')
      return
    }
    try {
      await updateDoc(chat(chatId), `presence.${this.userId}`, typing)
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public loadCaseNotes(isArchive: boolean = false) {
    const businessId = applicationStore.businessId
    const caseId = isArchive
      ? archiveStore.selectedArchive?.case?.id
      : this.context.rootGetters.selectedTextSession?.case?.id
    if (!businessId || !caseId) {
      return null
    }
    this.resetCaseNotes()
    const caseNotesQuery = query(businessCaseNotes(businessId, caseId), orderBy('createdDate', 'desc'));
    return onSnapshot(caseNotesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          const note = {id: change.doc.id, ...data};
          switch (change.type) {
            case 'added': {
              this._caseNotes.push(note);
              break;
            }
            case 'modified': {
              this._caseNotes.splice(this._caseNotes.findIndex((item) => item.id === note.id), 1, note);
              break;
            }
            case 'removed': {
              this._caseNotes.splice(this._caseNotes.findIndex((item) => item.id === note.id), 1);
              break;
            }
            default:
              console.log('Change unrecognized');
          }
        });
        this.setNotesLoaded(true)
      },
      (error) => console.log(error));

  }

  // todo: duplicates customerStore.loadNotes()
  @Action
  public loadCustomerNotes(archive: boolean = false) {
    const businessId = applicationStore.businessId;
    const textSession = archive ?
      this.context.rootGetters.selectedArchive :
      this.context.rootGetters.selectedTextSession
    if (!businessId || !textSession) {
      return () => null
    }
    this.resetCustomerNotes()
    const notesQuery = query(businessCustomerNotes(businessId, textSession.customer.id), orderBy('createdDate', 'desc'))
    return onSnapshot(notesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          const note = {id: change.doc.id, ...data};
          switch (change.type) {
            case 'added': {
              this._customerNotes.push(note);
              break;
            }
            case 'modified': {
              this._customerNotes.splice(this._customerNotes.findIndex((item) => item.id === note.id), 1, note);
              break;
            }
            case 'removed': {
              this._customerNotes.splice(this._customerNotes.findIndex((item) => item.id === note.id), 1);
              break;
            }
            default:
          }
        });
        this.setNotesLoaded(true)
      },
      (error) => console.log(error));
  }

  @Action
  public loadNoteReplies({note, isCaseNoteReplies}) {
    const businessId = applicationStore.businessId;
    const textSession = this.context.rootGetters.selectedTextSession;
    const caseId = textSession?.case?.id
    const customerId = textSession?.customer?.id
    if (!businessId || !note || !customerId || !caseId) {
      console.error('chat is not present')
      return null
    }
    if (this._selectedNote?.id !== note.id) {
      this.selectNote(note)
      this.resetNoteReplies()
    }
    const businessRef = business(businessId);
    let repliesQuery: any = isCaseNoteReplies
      ? businessCaseNoteReplies(businessId, caseId, note.id)
      : businessCustomerNoteReplies(businessId, customerId, note.id)

    repliesQuery = query(repliesQuery, orderBy('createdDate'));
    if (!!this._noteReplies?.length) {
      repliesQuery = query(repliesQuery, startAfter(this._noteReplies[this._noteReplies.length - 1].createdDate))
    }
    return onSnapshot(repliesQuery, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const reply = {id: change.doc.id, ...change.doc.data()}
        switch (change.type) {
          case 'added':
            this.putNoteReply(reply)
            break
          case 'modified':
            this.updateNoteReply({reply, oldIndex: change.oldIndex})
            break
          case 'removed':
            this.removeNoteReply(change.oldIndex)
            break
          default:
        }
      })
      this.setRepliesLoaded(true)
    });
  }

  @Action
  public async deleteCaseNoteReply(replyId?: string) {
    const businessId = applicationStore.businessId;
    const caseId = this.context.rootGetters.selectedTextSession?.case?.id
    if (!replyId || !businessId || !caseId || !this._selectedNote) {
      console.error('chat is not present')
      return
    }
    try {
      await deleteDoc(businessCaseNoteReply(businessId, caseId, this._selectedNote.id, replyId))
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async deleteCustomerNoteReply(data: { replyId: string, customerId: string }) {
    const businessId = applicationStore.businessId;
    const chatId = this.context.rootGetters.selectedTextSession?.id
    if (!businessId || !chatId || !this._selectedNote) {
      console.error('chat is not present')
      return
    }
    try {
      await deleteDoc(businessCustomerNoteReply(businessId, data.customerId, this._selectedNote.id, data.replyId))
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async saveNoteReply({replyText, replyId, caseNoteReply}) {
    const textSession = this.context.rootGetters.selectedTextSession
    const caseId = textSession?.case?.id
    const businessId = applicationStore.businessId;
    if (!businessId || !caseId || !this._selectedNote) {
      console.error('chat is not present')
      return
    }
    const noteId = this._selectedNote.id;
    const repliesRef: any = caseNoteReply
      ? businessCaseNoteReplies(businessId, caseId, noteId)
      : businessCustomerNoteReplies(businessId, textSession.customer.id, noteId)
    try {
      if (!!replyId) {
        await updateDoc(doc(repliesRef, replyId), 'text', replyText, 'edited', true)
      } else {
        let photoUrl = this.user?.photoUrl?.thumbnail
        let name = this.user?.fullName;
        let contactId = this.user?.defaultContactId || '';
        let uid = textSession.associate?.uid || null;
        if (this.userId === uid) {
          photoUrl = textSession.associate?.photoUrl?.thumbnail || ''
          name = textSession.associate?.name || '';
          contactId = textSession.associate?.id || '';
        } else {
          uid = this.userId
        }
        const replyData: any = {
          text: replyText,
          sender: {uid, name, contactId, type: 2},
          createdDate: Timestamp.now()
        };
        if (!!photoUrl) {
          replyData.photoUrl = photoUrl
        }
        await addDoc(repliesRef, replyData)
      }
    } catch (err) {
      console.error(err)
    }
  }

  @Action
  public async pinMessageToNote(message) {
    const businessId = applicationStore.businessId;
    const caseId = this.context.rootGetters.selectedTextSession?.case?.id
    if (!this.user || !businessId || !caseId) {
      return;
    }
    if (!message.pinned || !Object.keys(message.pinned).includes(this.userId!)) {
      const note = {
        owner: {
          id: this.userId,
          name: this.user.fullName,
          photoUrl: this.user.photoUrl?.normal || null
        },
        messages: [message],
        message,
        type: 2,
        createdDate: Timestamp.now()
      };
      await addDoc(businessCaseNotes(businessId, caseId), note);
      return true;
    } else {
      await updateDoc(chatMessage(message.textSessionId, message.id), `pinned.${this.userId}`, deleteField());
      return false;
    }
  }

  @Action
  public saveCaseNote({noteId, noteText, caseId}) {
    const businessId = applicationStore.businessId;
    if (!noteText || !this.user || !businessId) {
      return;
    }
    const notesRef = businessCaseNotes(businessId, caseId)
    if (noteId) {
      return updateDoc(doc(notesRef, noteId),
        'text', noteText,
        'updatedDate', Timestamp.now());
    }
    const owner: any = {
      id: this.userId,
      name: this.user.fullName,
      photoUrl: this.user.photoUrl?.normal || null
    };
    const note = {
      owner,
      createdDate: Timestamp.now(),
      text: noteText,
      type: 1,
      caseId
    };
    return addDoc(notesRef, note);
  }

  @Action
  public saveCustomerNote({noteId, noteText, caseId, customerId}) {
    const businessId = applicationStore.businessId;
    if (!noteText || !this.user || !businessId) {
      return;
    }
    const notesRef = businessCustomerNotes(businessId, customerId)
    if (noteId) {
      return updateDoc(doc(notesRef, noteId), 'text', noteText, 'updatedDate', Timestamp.now());
    }
    const owner: any = {
      id: this.userId,
      name: this.user.fullName,
      photoUrl: this.user.photoUrl?.normal || null
    };
    const note = {
      owner,
      createdDate: Timestamp.now(),
      text: noteText,
      type: 1
    };
    if (caseId) {
      // @ts-ignore
      note.caseId = caseId
    }
    return addDoc(notesRef, note);
  }

  @Action
  public deleteNote(note: any) {
    const businessId = applicationStore.businessId;
    const caseId = this.context.rootGetters.selectedTextSession?.case?.id
    if (!businessId || !caseId) {
      return;
    }
    return deleteDoc(businessCaseNote(businessId, caseId, note.id));
  }

  @Action
  public deleteCustomerNote({note, customerId}) {
    const businessId = applicationStore.businessId;
    if (!businessId || !note) {
      return;
    }
    return deleteDoc(businessCustomerNote(businessId, customerId, note.id));
  }

  @Action
  public starNote(note) {
    if (!this.user) {
      return;
    }
    const businessId = applicationStore.businessId;
    const caseId = this.context.rootGetters.selectedTextSession?.case?.id
    if (!businessId || !caseId) {
      return;
    }
    const starred = note.starred && note.starred[this.userId!];
    return updateDoc(businessCaseNote(businessId, caseId, note.id), `starred.${this.userId}`, !starred);
  }

  @Action
  public starCustomerNote({note, customerId}) {
    const businessId = applicationStore.businessId;
    if (!this.user || !businessId) {
      return;
    }
    const starred = note.starred && note.starred[this.userId!];
    return updateDoc(businessCustomerNote(businessId, customerId, note.id), `starred.${this.userId}`, !starred);
  }

  @Action
  public async shareChat() {
    const selectedChat = this.context.rootGetters.selectedTextSession
    if (!selectedChat || !this.userId) {
      return null;
    }
    const isInner = selectedChat.type === 3
    const isChat = selectedChat.case?.status === 2
    const isRejected = selectedChat.case?.status === 3
    const chatType = isChat ? 'chats' : 'requests'
    const chatSubtype = isChat ? (isInner ? 'inner' : 'active') : (isRejected ? 'rejected' : 'inbox')
    const axiosResponse = await axios.post('/shortLinks', {
      dynamicLinkInfo: {
        domainUriPrefix: `https://${dynamicLinkConfig.actionCodeSettings.dynamicLinkDomain}/share/chat`,
        link: `${dynamicLinkConfig.actionCodeSettings.url}/${chatType}/${chatSubtype}/${selectedChat.id}`,
        androidInfo: {
          androidPackageName: dynamicLinkConfig.actionCodeSettings.android!!.packageName
        },
        iosInfo: {
          iosBundleId: dynamicLinkConfig.actionCodeSettings.iOS!!.bundleId
        }
      },
      suffix: {
        option: 'SHORT'
      }
    }, {
      baseURL: 'https://firebasedynamiclinks.googleapis.com/v1',
      params: {key: appConfig.apiKey},
      headers: {
        ['Content-Type']: 'application/json'
      },
      validateStatus: (status) => status === 200
    });
    return axiosResponse.data.shortLink
  }

  @Mutation
  public setForwardedMessage(message: any) {
    this._forwardedMessage = message ? Object.assign({}, message) : null
  }

  @Mutation
  public setForwardedMessages(messages: any[] | null) {
    this._forwardedMessages = messages
  }

  @Action
  public clearForwarding() {
    this.setForwardedMessage(null)
    this.setForwardedMessages(null)
  }

  @Action
  public findChatIdForType({type, subtype}) {
    const key = `${type}:${subtype}`;
    let chatId = this.context.rootGetters.cachedLastTextSessions[key];
    if (!chatId) {
      const personal = subtype === 'personal';
      const chats = type === 'active' ? this.context.rootGetters.activeChats : this.context.rootGetters.inner
      chatId = chats.find((item) => item.memberIDs.includes(this.userId) === personal)?.id
      this.context.rootGetters.cachedLastTextSessions[key] = chatId
    }
    return chatId || ''
  }

  @Action
  public findFirstChatIdForType({type, subtype}) {
    const key = `${type}:${subtype}`;
    const prevChatId = this.context.rootGetters.cachedLastTextSessions[key];
    let chatId: string = ''
    const personal = subtype === 'personal';
    const chats = type === 'active' ? this.context.rootGetters.activeChats : this.context.rootGetters.inner
    chatId = chats.find((item) => item.id !== prevChatId && item.memberIDs.includes(this.userId) === personal)?.id
    this.context.rootGetters.cachedLastTextSessions[key] = chatId
    return chatId || ''
  }

  @Action
  public clearChatIdForType(typeKey: string) {
    deleteProperty(this.context.rootGetters.cachedLastTextSessions, typeKey)
  }

  @Mutation
  public lastChatRoute(fullPath: string) {
    this._chatFullPath = fullPath
  }

  @Action
  public async resetUnreadCount(chatId?: string) {
    if (!chatId) {
      return
    }
    // const unreadCount = newChat && newChat.unread && newChat.unread[this.userId!] || 0
    // if (!!unreadCount) {
    // }
    // todo: replace with go API
    const resetCounter = httpsCallable(functions, 'apiDbTextSessionsResetCounter')
    try {
      const response = await resetCounter({
        userId: this.userId,
        textSessionId: chatId
      })
      console.log(`apiDbTextSessionsResetCounter: ${response.data}`)
    } catch (err) {
      console.error(`apiDbTextSessionsResetCounter: ${err}`)
    }
  }

  @Action
  public async checkChatById({chatId, type, subtype}) {
    const activeChats: any[] = this.context.rootGetters.activeChats
    const predicate = (item) => item.id === chatId;
    // find in active chats
    let foundChat = activeChats.find(predicate);
    if (foundChat) {
      if (type === 'active' && subtype === 'personal') {
        return foundChat.memberIDs?.includes(this.userId)
      }
      return true
    }
    const innerChats: any[] = this.context.rootGetters.inner
    // find in inner chats
    foundChat = innerChats.find(predicate);
    if (foundChat) {
      return true
    }
    try {
      const snapshot = await getDoc(chat(chatId))
      if (snapshot.exists()) {
        if (type === 'active' && subtype === 'personal') {
          return snapshot.data()?.memberIDs?.includes(this.userId)
        }
        return true
      }
    } catch (e) {
      console.log(e);
    }
    return false
  }

  @Action
  public clearState() {
    this.resetChatStoreState()
  }
}

export const chatStore = getModule(ChatStore)
