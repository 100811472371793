




















import {Component, Prop, Vue} from 'vue-property-decorator'
import {channelsStore} from '@/store/modules/channels'
import {Channel} from '@/domain/model/types'
import {counterFormatter, formatTimestamp} from '@/utils/helpers'

@Component({
  name: 'channels-list-item',
  filters: {
    counterFormatter, formatTimestamp
  }
})
export default class ChannelsListItem extends Vue {
  @Prop() item?: Channel;

  get imageUrl() {
    return this.item?.imageUrl ?
        this.item?.imageUrl :
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  }

  get membersCount() {
    return this.item?.memberIDs?.length || 0
  }

  get lastMessage() {
    return this.item?.lastMessage?.text
  }

  get selected(): boolean {
    return channelsStore.selectedChannel?.id === this.item?.id
  }

  get disabled() {
    return !!this.item?.disabled
  }

  get itemClass(): string {
    let classList = ['channel-list-item']
    if (this.selected) {
      classList.push('selected')
    }
    if (this.disabled) {
      classList.push('disabled')
    }
    return classList.join(' ')
  }

  public onClick() {
    if (!this.selected) {
      this.$router.push({name: 'channel-detail', params: {channelId: this.item?.id || ''}})
    }
  }
}
