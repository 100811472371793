












import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({name: 'search-view'})
export default class SearchView extends Vue {
  @Prop({type: String, default: 'field-search'}) id
  @Prop({default: false}) blue?: boolean

  searchValue: string = ''

  get classIcon():string {
    const classList: string[] = ['icon']
    if (this.blue) {
      classList.push('search-blue')
    } else {
      classList.push('search')
    }
    return classList.join(' ')
  }
};
