import { render, staticRenderFns } from "./ContactsList.vue?vue&type=template&id=26635f9e&scoped=true&"
import script from "./ContactsList.vue?vue&type=script&lang=ts&"
export * from "./ContactsList.vue?vue&type=script&lang=ts&"
import style0 from "./ContactsList.vue?vue&type=style&index=0&id=26635f9e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26635f9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VDivider,VImg,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSheet,VVirtualScroll})
