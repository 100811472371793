
































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import ActiveChatList from '@/components/ActiveChatList.vue';
import {Getter} from 'vuex-class';
import DialogCreateNewActiveChat from '@/components/chats/DialogCreateNewActiveChat.vue';
import {profileStore} from '@/store/modules/profile';
import {chatStore} from '@/store/modules/chat/ChatStore';
import GpNotification from '@/components/custom/Notification.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import {tourStore} from "@/store/modules/tour";

@Component({
  name: 'active-chats',
  components: {DialogCreateNewActiveChat, ActiveChatList, GpNotification}
})
export default class ActiveChats extends mixins(Vue, Notifications) {
  @Prop() type?: string
  @Prop() subtype?: string

  showError: boolean = false;
  timeout: number = 5000;
  showCreateChatDialog: boolean = false
  userId?: string | null = profileStore.t2bUser?.id

  @Getter activeUrgentChats;
  @Getter activeNormalChats;
  @Getter activeLowChats;
  @Getter chatsError

  predicatePersonal = (chat) => chat.memberIDs.includes(this.userId);
  predicateAll = (chat) => !chat.memberIDs.includes(this.userId);

  get key() {
    return `${this.type}:${this.subtype}`
  }

  get isAll() {
    return this.subtype === 'all'
  }

  get urgentChats() {
    return this.isAll
        ? this.activeUrgentChats.filter(this.predicateAll)
        : this.activeUrgentChats.filter(this.predicatePersonal)
  }

  get normalChats() {
    return this.isAll
        ? this.activeNormalChats.filter(this.predicateAll)
        : this.activeNormalChats.filter(this.predicatePersonal)
  }

  get lowChats() {
    return this.isAll
        ? this.activeLowChats.filter(this.predicateAll)
        : this.activeLowChats.filter(this.predicatePersonal)
  }

  @Watch('chatsError') onChatsError(after, before) {
    this.showError = !!after
  }

  onChatSelected(chatId) {
    this.$router.push({name: 'active-chat', params: {chatId}, query: this.$route.query});
  }

  async onCaseForwarded() {
    chatStore.lastChatRoute('')
    await chatStore.clearChatIdForType(this.key)
    await this.$router.push({name: 'active', query: this.$route.query})
  }
}
